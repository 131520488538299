//.mat-raised-button,
//.mat-stroked-button,
//.mat-flat-button {
//  padding: 0 1.15em;
//  margin: 0 0.65em;
//  min-width: 3em;
//  line-height: 36.4px;
//}
//
//.mat-standard-chip {
//  padding: 0.5em 0.85em;
//  min-height: 2.5em;
//}
//
//.mat-expansion-panel.mat-expanded {
//  overflow: visible;
//}
//
//.mat-expansion-panel-header .mat-content {
//  display: inline-block;
//  width: 100%;
//}
//
//.mat-expansion-panel-header-description,
//.mat-expansion-panel-header-title {
//  flex-flow: row;
//  justify-content: space-between;
//}
//
//.mat-expansion-panel .mat-expansion-panel-header.mat-expansion-toggle-indicator-before {
//  align-items: flex-start;
//  padding: 15px 19px 15px 24px;
//  .mat-expansion-indicator {
//    margin: -2px 16px 0 0;
//    display: inline-block;
//    vertical-align: top;
//    &::after {
//      content: '\231F';
//      display: inline-block;
//      transform: rotate(45deg);
//      vertical-align: top;
//      font-size: 30px;
//      line-height: 30px;
//      border: none;
//    }
//  }
//}

mat-expansion-panel.expansion-panel {
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  box-shadow: none !important;
  margin-bottom: 15px !important;
  max-width: 1200px;
  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 18px;
    }
    .mat-expansion-indicator {
      transform-origin: center 12px;
      margin: 0 16px 3px 0;
      &::after {
        border-color: var(--primary-color);
      }
    }
  }
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      .form-wrapper {
        margin-left: 20px;
        margin-top: 0;
      }
    }
  }
}

.mat-form-field-disabled .mat-form-field-outline {
  background: #f6f6f6;
}
