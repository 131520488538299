.location-small-cell {
  width: 100px;
  @extend .ellipsis;
}
.location-medium-cell {
  @extend .ellipsis;
  width: 180px;
}

.active-location {
  .location-name,
  .location-type {
    @extend .ellipsis;
    padding-right: 25px;
    max-width: 200px;
  }

  .location-name span {
    @extend .ellipsis;
  }
}
