.mat-chip.mat-standard-chip {
  background-color: var(--primary-lighter-color);
  color: var(--primary-color);
  border-radius: 6px;
  margin: 4px 0px 4px 4px;
  .chip-text {
    max-width: 150px;
    font-size: 13px;
    @extend .ellipsis;
  }
}
.mat-chip.mat-standard-chip.new-role-chip {
  background-color: #2afd95;
}
.mat-chip.mat-standard-chip.to-delete {
  background-color: #FDECEC;
  border: 1px solid #8C0318; 
  color: #8C0318;
}