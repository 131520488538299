form.asset-form {
  margin-top: 0px;
}

.form-group {
  max-width: 1200px;
  margin: 10px 0 10px 75px;
}

.form-entry {
  width: 50%;
  display: inline-flex;
  padding-top: 10px;
  vertical-align: text-top;
  > mat-label {
    @extend .ellipsis;
  }
}

.form-divider {
  max-width: 1024px;
  margin-left: 0;
}
.form-header {
  color: var(--primary-color);
  max-width: 1200px;
  margin: 30px 0 25px 0;
  border-bottom: 1px #dddddd solid;
  padding: 0 0 5px 10px;
}
.form-subheader {
  color: #777777;
  font-size: 14px;
  margin: -18px 0 25px 0;
  padding: 0 0 5px 10px;
}
.expandable-header {
  color: var(--primary-color);
  cursor: pointer;
  span {
    vertical-align: middle;
  }
  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

.status-icon {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 15px;
}

.status-icon-active {
  background-color: #55bd5a;
}
.status-icon-pending {
  background-color: #fecc32;
}
.status-icon-inactive {
  background-color: #f54787;
}

@media screen and (max-width: 768px) {
  .form-group {
    margin: 10px 0 30px 20px;
  }
}

.activate-on-submit > .mat-list-item-content {
  margin-top: -45px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.dark-text-cbox > label > span {
  color: #1f1f1f;
}
