@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import 'app/shared/styles/mixins';
@import 'app/shared/styles/form';
@import 'app/shared/styles/button';
@import 'app/shared/styles/table';
@import 'app/shared/styles/chip';
@import 'app/shared/styles/expansion-panel';
@import 'app/shared/styles/sticky-header';
@import 'app/shared/styles/sidenav';

global-footer {
  display: block;
  padding: 40px 25px 25px;
}

button.primary-btn,
button {
  background: var(--primary-color);
  color: white;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

.expandable-bar {
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

k-in {
  color: #717171 !important;
}

.k-in.k-state-focused,
.k-in:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.alert {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-padding {
  padding: 0.75rem 1.25rem;
}

.squared {
  border-radius: 5px !important;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  button {
    color: var(--primary-color);
    border: 1px solid;
    border-color: var(--primary-color);
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 600px) {
  multi-locale-input {
    width: 100%;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  multi-locale-input {
    width: 100%;
    line-height: 1.2;
    padding-right: 40px;
    margin-bottom: 10px;
  }
}

.mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.k-treeview .k-in {
  height: 28px;
  padding: 6px 8px 6px 0px;
}

.k-treeview .k-checkbox-wrapper {
  margin-left: 8px;
}

.k-checkbox {
  border-color: var(--primary-color);
}

.mat-tab-header {
  width: 95%;
}

.mat-tab-label {
  padding: 0 18px !important;
  min-width: 100px !important;
}

li[ng-reflect-is-selected='true'] > div > kendo-checkbox {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

li[ng-reflect-is-selected='true'] > div > kendo-checkbox > input {
  border-color: #717171;
}

li[ng-reflect-is-selected='true'] > div > span > mat-icon {
  color: #717171 !important;
}

.k-treeview .k-in.k-state-selected {
  color: rgba(0, 0, 0, 0.67) !important;
  opacity: 0.6;
}

.k-treeview > .k-group {
  max-height: 280px;
  min-height: 100px;
}

.mat-dialog-container {
  padding: 30px !important;
}

.mat-tooltip-trigger > svg > path {
  fill: var(--primary-color) !important;
}

.tree-node-margin {
  background-color: var(--primary-color) !important;
}

.tree-node:hover {
  background-color: var(--primary-lighter-color) !important;
}

.enel-search:focus {
  border-color: var(--primary-color) !important;
}
